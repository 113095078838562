import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import axios from 'axios'

import logo from './logo.png'
import './App.css'
import background from './background.jpg'

function App() {
	const [email, setEmail] = useState('')
	const [message, setMessage] = useState('')
	const [status, setStatus] = useState('')

	const handleSubmit = async (e) => {
		e.preventDefault()
		if ((!email, !message)) {
			alert('You must complete all fields!')
		} else {
			await axios
				.post('/api/email', {
					email: email,
					message: message,
				})
				.then((response) => {
					setStatus(response.data.message)
				})
				.catch((error) => {
					setStatus(error.response.data.message)
				})
		}
	}

	useEffect(() => {
		if (status !== '') {
			alert(status)
			setEmail('')
			setMessage('')
		}
	}, [status])

	return (
		<div className="App">
			<Helmet>
				<style>{'body { background-color: #CAC4CE; }'}</style>
			</Helmet>
			<header
				className="App-header"
				style={{ backgroundImage: `url(${background})` }}
			>
				<img src={logo} className="App-logo" alt="logo" />
				<p>
					General Contracting | Property Maintenance | Lawn Care | Pressure
					Washing | Junk Removal
				</p>
			</header>
			<div className="body">
				<h1 className="text">Contact Us Today For An Estimate</h1>
				<center>
					<Form onSubmit={handleSubmit}>
						<Form.Group className="mb-3 w-75" controlId="formBasicEmail">
							<Form.Label>Email address</Form.Label>
							<Form.Control
								type="email"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value)
								}}
								placeholder="Enter email"
							/>
						</Form.Group>

						<Form.Group className="mb-3 w-75" controlId="formBasicText">
							<Form.Label>Your Inquiry</Form.Label>
							<Form.Control
								as="textarea"
								rows={3}
								value={message}
								onChange={(e) => {
									setMessage(e.target.value)
								}}
								placeholder="Your Inquiry"
							/>
						</Form.Group>
						<Button variant="primary" type="submit">
							Submit
						</Button>
					</Form>
				</center>
			</div>
			<footer className="footer">&copy; 2023 Kelton Services LLC</footer>
		</div>
	)
}

export default App
